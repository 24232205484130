import React, { useState, useRef, useEffect } from "react";
import Icon from "../Icon";
import careerData from "../../../assets/data/careersMenu.json"
import RemoveChip from "../chips/RemoveChip";

const AutocompleteRoles = (props) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(props.selected);
    const [resultList, setResultList] = useState({jobs:[]});
    const [recent, setRecent] = useState(["Mechanical Engineer","Quality Engineer","Software Engineer","Manufacturing Engineer","Electrical Engineer"])
    const [showClear, setShowClear] = useState(false);
    const input = useRef();
    const container = useRef();

    const allJobs = useRef([]);

    useEffect(() => {
        //create search list
        careerData.forEach(cluster => {
            cluster.pathways.forEach(path => {
                // let pathObj = {};
                // pathObj.code = path.code;
                // pathObj.title = path.title;
                // allPathways.current.push(pathObj)

                path.occupations.forEach(job => {
                    let jobObj = {};
                    jobObj.code = job.code
                    jobObj.title = job.title

                    allJobs.current.push(jobObj)
                })
            })
        })

        // console.log(allJobs.current)
        // console.log(allPathways.current)
    
    },[])

    // useEffect(() => {
    //     setSelected(props.default)
    //     if (props.default.length > 0) {setShowClear(true)}
    // },[props.default])

    useEffect(() => {
        let autocomplete = container.current;
        const handleClick = (event) => {
            if (autocomplete && autocomplete.contains(event.target)) {
                //do nothing
            } else {
                // console.log("close", event.target)
                setOpen(false)
            }
        }
        document.addEventListener("click",handleClick)
        document.addEventListener('keydown', event => {
            if (event.key === "Escape") {
                setOpen(false)
            }
        })
        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('keydown', event => {
                if (event.key === "Escape") {
                    setOpen(false)
                }
            })
        }
    },[])

    const onInput = (event) => {
        
        // console.log(event.target.value)
        if (event.target.value.length > 0) {
            
            setShowClear(true)
            setOpen(true)
            filterList(event.target.value)
        } else {
            setShowClear(false)
            // setOpen(false)
        }
        // if (event.target.value.length > 2) {
            
        // } 
    }
    const addToSelected = (item) => {
        input.current.value = ''

        setSelected([...selected, item])
        // props.search(item)
        setOpen(false)
        setShowClear(true)
        filterList('')
        props.addToSelected([...selected, item])
    }

    const removeFromSelected = (item) => {
        const currSelected = [...selected];

        const removeIndex = currSelected.indexOf(item);
        const removed = currSelected.splice(removeIndex,1);
        // console.log("check", currSelected)
        setSelected(currSelected)
        props.addToSelected(currSelected)
    }


    const clearInputFilter = () => {
        input.current.value = ''
        // setSelected('')
        setOpen(false)
        setResultList({jobs:[]})
        setShowClear(false)
        // props.search('')
        input.current.focus()
    }
    const onEnter = (event) => {
        if (event.key === "Enter") {
            //search
            // props.search(event.target.value)
            setOpen(false)
        }
    }

    const filterList = (str) => {
        // let newcompanyList = searchTerms.filter(c => c !== selected)
        //remove any already selected
        
        let filteredListJobs;
        //filter by input
        if (str.length > 0) {
            let filteredList = allJobs.current.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            // filteredListPath = allPathways.current.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
            filteredListJobs = filteredList.length > 0 ? filteredList : ["None"]

            // filteredListPath = allPathways.current.filter(name => name.title.toLowerCase().includes(str.toLowerCase()))
             
            // (post) => {
            //     return post.title.toLowerCase().match(searchValue.toLowerCase());
            //  });
        } else {
            // filteredListPath = allPathways.current
            filteredListJobs = []
        }
        
        setResultList({jobs:filteredListJobs})
    }
    const boldQuery = (query, text) => {
        const resultText = text;
        const result = resultText.replace(new RegExp(`(${query})`, 'gi'), '<strong>$1</strong>');

        return (
            result
        )
    }

    return (
        <div className="career-search-list" ref={container}>
            <div className="autocomplete-container ">
                <div className="search-input">
                    <input type="text" className="gr-input" 
                    aria-label={props.placeholder}
                    role="combobox" aria-autocomplete="list" aria-expanded={open} aria-haspopup="listbox"
                    aria-controls="jobSearchList"
                    placeholder={props.placeholder}
                    onChange={onInput}
                    onClick={() => setOpen(true)}
                    // onKeyDown={(event) => onEnter(event)}
                    ref={input}
                    />
                    <span className="search-icon">
                        <Icon icon="search" size="24"/>
                    </span>
                    
                    <button hidden={!showClear} className="button medium icon-btn clear-icon" aria-label="Clear search" onClick={() => clearInputFilter()}>
                        <Icon icon="close" size="24"/>
                    </button> 
                </div>
                
                <div className="autocomplete-list" style={open && (resultList.jobs?.length > 0 || recent.length > 0) ? null : {display:"none"}} id="jobSearchList" role="listbox">
                    {/* <!-- use keyboard behavior from mui component --> */}
                    {resultList.jobs.length > 0 ? 
                    
                    <ul>
                        
                        {resultList.jobs?.length > 0 ? 
                            
                            <li className="occupation-results" role="presentation">
                                {resultList.jobs.length === 1 && resultList.jobs[0].toLowerCase() === 'none' ? 
                            
                                    <span className="no-results">No results found</span>
                                
                                    : <>
                                    <h2 className="result-category">Suggestions</h2>
                                    <ul className="results-list">
                                        {resultList.jobs.map((job,index) => {
                                            return (
                                                <li role="presentation" key={"job"+job.code+index}>
                                                    <button onClick={() => addToSelected(job.title)} dangerouslySetInnerHTML={{__html: boldQuery(input.current?.value, job.title)}}></button>
                                                </li>
                                            )
                                        })}
                                        
                                    </ul></>}

                            </li> 
                        : <></>}

                    </ul> : 
                    <ul>
                        <li className="occupation-results" role="presentation">
                            <h2 className="result-category">Recent roles</h2>
                            <ul className="results-list">
                                {recent.map((job,index) => {
                                    return (
                                        <li role="presentation" key={"jobrecent"+index}>
                                            <button onClick={() => addToSelected(job)}>{job}</button>
                                        </li>
                                    )
                                })}
                                
                            </ul>

                        </li>
                    </ul> }
                </div> 
                
            </div>
            <div className="selected-list">
                    {selected.length > 0 ?
                    <ul className="chip-list">
                        {selected.map((job,index) => {
                            return (
                                <li role="presentation" key={job.replace(" ","-") + index}>
                                    <RemoveChip label={job} remove={() => removeFromSelected(job)}/>
                                </li>
                            )
                        })}
                    </ul> 
                    
                    : <></>}
                </div>
        </div>
    )
}
export default AutocompleteRoles