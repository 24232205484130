import React, {useEffect, useRef, useState} from "react";
import Header from "../global/Header";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import MegaMenu from "../global/megamenu/MegaMenu";
import Menu from "../global/megamenu/Menu";
import MobileMenu from "../global/nav/MobileMenu";

const HorizontalNavMenu = (props) => {
    const [value, setValue] = useState(props.active);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [careersMenuOpen, setCareersMenuOpen] = useState(false);

    const nav = useRef();
    const menu = useRef(); 
    const slider = useRef();
    const tabList = useRef();

    const navItems = [
        {
            item: 0,
            title: "Dashboard",
            url: 'dashboard',
            icon: ''
        },
        {
            item: 3,
            title: "Explore careers",
            url: 'careers',
            icon: ''
        },
        {
            item: 1,
            title: "Find jobs",
            url: 'jobs',
            icon: ''
        },
        {
            item: 2,
            title: "Build skills",
            url: 'skills',
            icon: ''
        },
        
        {
            item: 4,
            title: "Profile",
            url: 'profile',
            icon: ''
        }
    ]

    const tabClick = (event, val) => {
        const queryString = window.location.search;
        let params = new URL(document.location).searchParams;

        
        const urlParams = new URLSearchParams(queryString);
        console.log(params)
        console.log(urlParams.toString())

        if (props.params) {
            navigate({
                pathname: process.env.PUBLIC_URL + "/" + val.url,
                search: '?'+props.params
            })
        } else if (queryString) {
            let newQString = urlParams;
            if (newQString.has('search')) {
                console.log("remove search from params")
                newQString.delete('search')
            }
            if (newQString.has('globalAlerts')) {
                console.log("remove global alerts from params")
                newQString.delete('globalAlerts')
            }

            navigate({
                pathname: process.env.PUBLIC_URL + "/" + val.url,
                search: newQString.toString()
            })
        }  else {
            navigate({
                pathname: process.env.PUBLIC_URL + "/" + val.url
            })
        }
        setCareersMenuOpen(false)
        setValue(val.url);
    }
    const changeGlobalEmailPref = () => {
        const queryString = window.location.search;
        console.log(queryString)
        if (queryString) {
            // navigate(
            //     {pathname:'/jobs',
            //     search:`?${createSearchParams({search:searchText.replace(" ","+"),globalAlerts:!globalAlerts})}`
            //     }
            // )
        }
    }

    const positionSlider = () => {
        let tabListBox = tabList.current.getBoundingClientRect(),
            tabListBoxLeft = tabListBox.left;

            if (tabList.current.querySelector("[aria-selected=true]")) {
                let activeTabBox = tabList.current.querySelector("[aria-selected=true]").getBoundingClientRect(),
                tabLeft = activeTabBox.left,
                tabWidth = activeTabBox.width;
                console.log(activeTabBox)
                console.log(tabListBox)
                slider.current.style.left = tabLeft - tabListBoxLeft + 'px';
                slider.current.style.width = tabWidth + 'px';
            }
    
    }

    const closeMobileMenu = () => {
        setOpen(false)
        document.body.removeAttribute('style')
    }
    useEffect(() => {
        if (menu.current) {
            menu.current.classList.add("animate-in")
            
        }
        // if (open) {
        //     document.body.querySelector(".tab-container").classList.add("overflow-hidden")
        // } else {
        //     document.body.querySelector(".tab-container").classList.remove("overflow-hidden")
        // }
    },[open])
  
    useEffect(() => {
        setValue(props.active)
    },[props.active])

    useEffect(() => {
        let navigation = nav.current
        document.addEventListener('click', event => {
            if (navigation.contains(event.target)) {
                //do nothing
            } else {
                //close careers menu
                setCareersMenuOpen(false)
            }
        })
        return () => {
            document.removeEventListener('click', event => {
                if (navigation.contains(event.target)) {
                    //do nothing
                } else {
                    //close careers menu
                    setCareersMenuOpen(false)
                }
            })
        }
    },[])

    useEffect(() => {
        setTimeout(() => positionSlider(), 60)
        //try second timeout to re-render to right size
        setTimeout(() => positionSlider(), 400)
        
    },[value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.selectTab(newValue)
      };
    

    return (
        <nav aria-label="Main" className={open ? "horizontal-navigation open" : "horizontal-navigation"} ref={nav}>
            <div className="header-wrapper">
                <div className="mobile-only menu-icon">
                    <button className="button icon-btn menu-btn" aria-expanded={open} aria-label="Menu" onClick={open ? () => closeMobileMenu() : () => setOpen(true)}>
                        <svg focusable="false" className={"icon-24"} aria-hidden="true">
                            <rect x="4" y="6" width="16" height="2" rx="1"></rect>
                            <rect x="4" y="11" width="16" height="2" rx="1"></rect>
                            <rect x="4" y="16" width="16" height="2" rx="1"></rect>
                        </svg>
                    </button>
                </div>
                <Header mode="dark" search={true} logo={true} auth={props.auth}>
                    <div className="tab-container main-nav">
                        <div className="tab-list-container" ref={tabList}>  
                            <ul className="tab-list"
                            >
                                {navItems.map((option,index) => {
                                    
                                        if (option.title === 'Explore careers') {
                                           return (<li className={value === option.url ? "tab-item active" : "tab-item"} key={"navitem"+index}>
                                                <MegaMenu tabClick={(event) => tabClick(event,option)} selected={value === option.url} openMenu={() => setCareersMenuOpen(true)} closeMenu={() => setCareersMenuOpen(false)} open={careersMenuOpen}/>

                                                </li>)
                                        } else {
                                            return (
                                                <li className={value === option.url ? "tab-item active" : "tab-item"} key={"navitem"+index}>
                                                <button className="tab-action" role="tab" onClick={(event) => tabClick(event,option)}  aria-selected={value === option.url}>
                                                    {option.title}
                                                    <span className="hidden-bold">{option.title}</span>
                                                    </button>
                                                    {/* <Link to={process.env.PUBLIC_URL + "/" + option.url} className="tab-action" role="tab" aria-selected={value === option.url}>{option.title}</Link> */}
                                            </li>
                                            )
                                        }
                                    
                                    
                                })}
                                
                            </ul>
                        <div className="tab-slider" ref={slider}></div>
                        </div>
                    </div>

                </Header>
                {careersMenuOpen && <Menu close={() => setCareersMenuOpen(false)}/>}
            </div>
            {open ? 
             <div className="nav-menu fadeIn" ref={menu}>
             {/* <h1>Welcome, Jeremy!</h1> */}
             <MobileMenu open={() => setOpen(true)} navItems={navItems} selected={value} close={() => closeMobileMenu(false)}/>
             {/* <nav>
                 <ul>
                 {navItems.map((option,index) => {
                            return (
                            <li className={value === option.url ? "tab-item active" : "tab-item"} key={"navitem"+index}>
                                    <Link to={process.env.PUBLIC_URL + "/" + option.url} onClick={() => setOpen(false)} className="tab-action" role="tab" aria-selected={value === option.url}>{option.title}</Link>
                            </li>
                            )
                        })}
                 </ul>
             </nav> */}
         </div> : <></>
            }
                            
           
        </nav>
    )
}
export default HorizontalNavMenu