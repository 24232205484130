import React, {useState} from "react";
import Icon from "../../global/Icon";

const AlertToggle = (props) => {
    const [checked, setChecked] = useState(props.checked);

    const onChange = () => {
        if (checked) {

            setChecked(false)
        } else {
            setChecked(true)
            props.toggle()
        }
    }
    return (
        <div className="toggle dark">
            <span className="toggle-label left" id="alertLabel">Set alert</span>
            <button className="set-alert-switch" role={"button"} aria-labelledby="alertLabel" 
                aria-pressed={props.checked} onClick={() => props.toggle()}>
                <span className="off">
                    <Icon icon="close" size="16"/>
                </span>
                <span className="on">
                    <Icon icon="correct" size="16"/>
                </span>
            </button>
        </div>
    )
}
export default AlertToggle