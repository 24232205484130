import React, { useEffect, useState, useRef } from "react";
import Icon from "../../global/Icon";
import FilterMenuLocation from "./FilterMenuLocation";
import FilterMenuType from "./FilterMenuType"
import FilterMenuCompensation from "./FilterMenuCompensation"
import FilterMenuOffice from "./FilterMenuOffice"
import FilterMenuDate from "./FilterMenuDate";
import FilterMenuRole from "./FilterMenuRole";


const FilterDropdown = (props) => {
    let filterType = props.type;
    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState(props.filters);
    const [announcement, setAnnouncement] = useState('');
    const dropdown = useRef();

    useEffect(() => {
        setFilters(props.filters)
    },[props.filters])

    useEffect(() => {
        let element = dropdown.current;
        const dropdownClick = (event) => {
            
            console.log(event.target, event.target.parentNode)
            if (dropdown.current.contains(event.target) || dropdown.current.contains(event.target.parentNode.parentNode.parentNode)) {
                //do nothing
            } else {
                setOpen(false)
                console.log("close dropdown")
            }
        }
        if (element && open) {
            document.addEventListener("click", dropdownClick)
            document.addEventListener('keydown', event => {
                if (event.key === "Escape") {
                    setOpen(false)
                }
            })
        }
        

        return (() => {
            if (element) {
                document.removeEventListener("click", dropdownClick)
                document.removeEventListener('keydown', event => {
                    if (event.key === "Escape") {
                        setOpen(false)
                    }
                })
            }
            
        })
    })
    
    const modifyFilter = (items) => {
        console.log(items)
        setFilters({...filters, selected: items})
    }

    return (
        <div className={open ? "dropdown open" : "dropdown"} ref={dropdown}>
            <button className="filter-btn dropdown-btn" onClick={() => setOpen(!open)} aria-expanded={open}>
                <span>
                    {filterType}
                    {props.filters.selected.length > 0 &&
                        <span className="filter-amount">{props.filters.selected.length}</span>
                    }
                </span>
                <Icon icon="drop-down" size="18"/>
            </button>
            {open &&  <div className="menu fadeIn">

                {filterType.toLowerCase() === 'type' ? 
                    <FilterMenuType data={filters} modify={(sel) => modifyFilter(sel)}/> 
                    : filterType.toLowerCase() === 'location' ?
                    <FilterMenuLocation data={filters} modify={(sel) => modifyFilter(sel)}/>
                    : filterType.toLowerCase() === "role" ? 
                    <FilterMenuRole data={filters} modify={(sel) => modifyFilter(sel)}/>
                    : filterType.toLowerCase() === "onsite/remote" ? 
                    <FilterMenuOffice data={filters}  modify={(sel) => modifyFilter(sel)}/>
                    : filterType.toLowerCase() === 'compensation' ?
                    <FilterMenuCompensation data={filters}  modify={(sel) => modifyFilter(sel)}/>
                    : filterType.toLowerCase() === 'date posted' ? 
                    <FilterMenuDate data={filters}  modify={(sel) => modifyFilter(sel)}/>
                    : <p>No filter menu available</p>
                }

                <div className="menu-footer">
                    <button className="button light outline small" onClick={() => {modifyFilter([]);props.apply({...filters, selected: []});setOpen(false);setAnnouncement(filterType + ' filter cleared')}}>Clear</button>
                    <button className="button primary small" onClick={() => {props.apply(filters);setOpen(false);setAnnouncement(filterType + ' filter applied')}}>Apply filter</button>
                </div>
                <div role="region" className="live-region visuallyHidden" aria-live="polite">
                <p>{announcement}</p>
                </div>
            </div>}
        </div>
    )
}
export default FilterDropdown