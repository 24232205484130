import React, {useState} from "react";
import Icon from "../../global/Icon";
import Accordion from "../../global/Accordion";
import FilterMenuLocation from "./FilterMenuLocation";
import FilterMenuType from "./FilterMenuType"
import FilterMenuCompensation from "./FilterMenuCompensation"
import FilterMenuOffice from "./FilterMenuOffice"
import FilterMenuDate from "./FilterMenuDate";
import FilterMenuRole from "./FilterMenuRole";

const FilterDrawer = (props) => {
    const [filters, setFilters] = useState(props.filters);
    const [announcement, setAnnouncement] = useState('');
    const modifyFilter = (type, items) => {
        let existingFilters = [...filters],
            filterToModify = existingFilters.findIndex(f => f.type.toLowerCase() === type);

            // console.log(filters, type, filterToModify)

            if (filterToModify > -1) {
                existingFilters[filterToModify].selected = items
            }
        // console.log(items)
        setFilters(existingFilters)
    }
    const clearAllFilters = () => {
        return [{type:"Type",selected:[]},{type:"Location",selected:[]},{type:"Role",selected:[]},{type:"Onsite/Remote",selected:[]},{type:"Compensation",selected:[]},{type:"Date Posted",selected:[]}]
    }
    return (
        <div className="drawer filter-drawer">
            <div className="modal-header">
                <h1 className="modal-title desktop-h3">
                    Filters
                </h1>
                <button className="button icon-btn close-btn" aria-label="Close" onClick={() => props.close()}>
                    <Icon icon="close" size="24"/>
                </button>
            </div>
            <div className="modal-body">
                {props.filters.map((filter, index) => {
                    let label = filter.selected.length > 0 ? ('<span>' + filter.type + '<span class="filter-amount">' + filter.selected.length +'</span></span>') : filter.type
                    return (
                        <Accordion label={label} key={"filtergroup"+index} modifier={"filter-accordion"}>
                                {filter.type.toLowerCase() === 'type' ? 
                                    <FilterMenuType data={filter} modify={(sel) => modifyFilter('type',sel)}/> 
                                    : filter.type.toLowerCase() === 'location' ?
                                    <FilterMenuLocation data={filter} modify={(sel) => modifyFilter('location',sel)}/>
                                    : filter.type.toLowerCase() === "role" ? 
                                    <FilterMenuRole data={filter} modify={(sel) => modifyFilter('role',sel)}/>
                                    : filter.type.toLowerCase() === "onsite/remote" ? 
                                    <FilterMenuOffice data={filter} modify={(sel) => modifyFilter("onsite/remote", sel)}/>
                                    : filter.type.toLowerCase() === 'compensation' ?
                                    <FilterMenuCompensation data={filter} modify={(sel) => modifyFilter('compensation',sel)}/>
                                    : filter.type.toLowerCase() === 'date posted' ? 
                                    <FilterMenuDate data={filter} modify={(sel) => modifyFilter('date posted',sel)}/>
                                    : <p>No filter menu available</p>
                                }
                        </Accordion>
                    )
                })}
                
            </div>
            <div className="modal-footer docked">
                <button className="button light outline small" onClick={() => {props.apply(clearAllFilters());setAnnouncement('All filters cleared.')}}>Clear</button>
                <button className="button primary small" onClick={() => {props.apply(filters);props.close();setAnnouncement('Selected filters applied.')}}>Apply</button>
            </div>
            <div role="region" className="live-region visuallyHidden" aria-live="polite">
                <p>{announcement}</p>
            </div>
        </div>
    )
}
export default FilterDrawer